@import url("https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap");
/* font-family: 'Archivo Black', sans-serif; */
@import url("https://fonts.googleapis.com/css2?family=Almarai:wght@400;700&display=swap");
/* font-family: 'Almarai', sans-serif; */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800&display=swap"); /* font-family: 'Montserrat', sans-serif; */
@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600;800&display=swap");
/* font-family: 'Orbitron', sans-serif; */

body {
  color: #fff;
  background-color: #000;
  font-family: "Montserrat", sans-serif;
}

.abf {
  font-family: "Archivo Black", sans-serif;
}

.uni {
  border: 0;
  margin: 0 auto;
  margin-bottom: 0.5rem;
  display: block;
  border-radius: 10px;
  max-width: 960px;
  min-width: 300px;
  max-height: 500px;
}

.cusbtn {
  background: #f8be13;
  box-shadow: 0px 0px 3px 1px #000;
  border-radius: 10px;
  padding: 10px 35px;
  text-decoration: none;
  color: #000 !important;
  text-decoration: none !important;
  text-transform: uppercase;
  font-size: 24px;
  max-width: 360px;
  line-height: 1.3em;
  letter-spacing: 1px;
  text-align: center;
  transition: all 0.3s ease 0s;
}

.navlogo {
  height: 81px;
}

.section1 {
  background-image: url("./assets/bg.jpeg");
  width: 100%;
  /* height: 84vh; */
  background-repeat: no-repeat;
  background-size: cover;
}

.sec1_img {
  margin-top: 2rem;
  border-radius: 50%;
  height: 600px;
  border: 1rem solid #422309;
}

.sect_t1 {
  font-size: 2rem;
}

.sect_t2 {
  font-size: 1.9rem;
}

.zi {
  margin-bottom: -3rem;
}

.blackbox {
  background-color: #422309;
  border-radius: 1rem;
  padding: 1rem 0;
}

.shlogo {
  height: 100px;
}

.slogo {
  height: 350px;
}

.logo {
  font-family: "Fredoka", sans-serif;
  font-weight: bold;
}

.flogo {
  font-family: "Montserrat", sans-serif;
}

.cus-nav {
  /* background-color: transparent; */
  background-color: #422309;
  color: #fff;
  padding: 0.4rem 0;
}

.secBox {
  padding: 4rem 0;
}

.sec2 {
  background-color: #b69615;
  color: #000;
  display: flex;
  justify-content: center;
}

.sec2_img {
  height: 500px;
}

.sec2_box {
  background-color: #6d9f46;
  border-radius: 3rem;
  padding: 0 3rem;
}

.sec2_t1 {
  /* margin-top: 3rem; */
  font-size: 2rem;
  font-family: "Montserrat", sans-serif;
}

.sec2_t2 {
  font-size: 1.37rem;
  font-family: "Montserrat", sans-serif;
}

.sec3 {
  padding-top: 3rem;
  background: linear-gradient(to right, #ed3b88 0%, #841258 100%);
}

.sec3_t1 {
  font-family: "Orbitron", sans-serif;
  font-weight: bold;
  font-size: 3rem;
}

.accordion-item {
  margin: 1rem 0;
}

.accordion-title {
  display: flex;
  align-items: center;
}

.subtitle {
  font-size: 1.4rem;
  letter-spacing: 2px;
  font-weight: bold;
}

.mmt {
  margin-top: 8rem;
}

.buy_img {
  height: 130px;
}

.boxbuy {
  border: 1px solid #fff;
  border-radius: 2rem;
  padding: 2rem;
  background-color: rgba(0, 0, 0, 0.356);
}

.mob {
  display: none;
}
.desk {
  display: block;
}

.pxx {
  margin: 0 4rem;
}

.jarrow {
  font-size: 2rem !important;
}

.my3 {
  margin: 3rem 0;
}

.bigtext {
  font-size: 1.4rem !important;
}

.smmtaxt {
  font-size: 1rem;
}

.let {
  height: 500px;
}

.bigtextt {
  font-size: 1.8rem;
}

.slo {
  height: 70px;
  margin: 0 4rem;
}

.AuthorProfileCard_box_info_address input {
  outline: none;
  color: #fff;
  width: 20%;
  font-size: 1rem;
  border: none;
  background-color: transparent;
}

@media only screen and (max-width: 600px) {
  .slo {
    height: 40px;
    margin: 0 2rem;
  }
  .bigtextt {
    font-size: 1.4rem;
  }

  .let {
    height: 300px;

    margin: 2rem 0;
  }
  .smmtaxt {
    font-size: 0.8rem;
  }

  .jarrow {
    font-size: 1.3rem !important;
  }

  .my3 {
    margin: 1rem 0;
  }

  .pxx {
    margin: 0 1rem;
  }

  .stt {
    font-size: 0.8rem !important;
  }

  .navlogo {
    height: 40px !important;
  }

  .sect_t1 {
    font-size: 1rem;
  }

  .sect_t2 {
    font-size: 1.2rem;
    margin: 0 1rem !important;
  }

  .shlogo {
    height: 70px;
  }

  .slogo {
    height: 100px;
  }

  .sec1_img {
    height: 350px;
    border: 0.5rem solid #000;
  }

  .rmt-1 {
    margin-top: 1rem !important;
  }
  .rmt-2 {
    margin-top: 2rem;
  }
  .rmt-3 {
    margin-top: 3rem;
  }
  .rts {
    font-size: 1.2rem !important;
  }
  .rtj {
    text-align: justify;
  }

  .section1 {
    background-position: center;
    /* height: 45vh; */
  }

  .navlogo {
    font-size: 2rem;
  }

  .sec2_t1 {
    margin-top: 0rem;
    font-size: 1.6rem;
  }

  .sec2_t2 {
    font-size: 1.2rem;
  }

  .sec2_box {
    padding: 2rem 1rem;
  }

  .sec2_img {
    padding: 0 !important;
    height: auto;
  }

  .sec3_t1 {
    font-size: 2rem;
  }

  .mob {
    display: block;
  }
  .desk {
    display: none;
  }

  .flogo {
    font-size: 1.4rem;
    font-weight: bold;
    margin-top: 1rem;
  }

  .aff {
    font-size: 1.2rem !important;
  }

  .blackbox {
    padding: 0.5rem 0.3rem !important;
  }

  .uuuuu {
    margin: 0 !important;
  }
}
